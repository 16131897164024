@font-face {
  font-family: "nta";
  src: url("./assets/fonts/light-94a07e06a1-v2.woff2") format("woff2"),
    url("./assets/fonts/light-f591b13f7d-v2.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: "nta";
  src: url("./assets/fonts/bold-b542beb274-v2.woff2") format("woff2"),
    url("./assets/fonts/bold-affa96571d-v2.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
